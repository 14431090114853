export default class Organization {
  static readonly DEFAULT_BANNER_HEX_CODE = "#606060";

  readonly id: number;
  readonly name: string;
  readonly nickname: string;
  readonly anniversaryWord: string;
  readonly logoFileName: string;
  readonly bannerHexCode: string;
  readonly bannerGrayscale: number;
  readonly anniversaryWordHexCode: string;

  constructor(
    id: number,
    name: string,
    nickname: string,
    anniversaryWord: string,
    logoFileName: string,
    bannerHexCode: string | null,
    bannerGrayscale: number | null,
    anniversaryWordHexCode: string | null
  ) {
    this.id = id;
    this.name = name;
    this.nickname = nickname;
    this.anniversaryWord = anniversaryWord;
    this.logoFileName = logoFileName;
    this.bannerHexCode = bannerHexCode = bannerHexCode ? bannerHexCode : Organization.DEFAULT_BANNER_HEX_CODE;
    this.bannerGrayscale = bannerGrayscale ? bannerGrayscale : 96;
    this.anniversaryWordHexCode = anniversaryWordHexCode ? anniversaryWordHexCode : "black";
  }
}
