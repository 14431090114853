import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError } from
"@remix-run/react";

import "./styles/normalize-boilerplate.css?__remix_sideEffect__";
import "./styles/root.css?__remix_sideEffect__";
import MessageBanner from "./components/message-banner";
import Organization from "./model/contributor/organization";
import Contributor from "./model/contributor/contributor";
import { useRouteData } from "./model/util/http/remix";
import { ThankYouLoaderData } from "./routes/thank-you.$uuid";

export const links: LinksFunction = () => [...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];

export default function App() {
  const organization = useOrganization();
  const logoFileName = organization?.logoFileName ?? "1x1.png";
  const bannerColor = organization?.bannerHexCode ?? Organization.DEFAULT_BANNER_HEX_CODE;
  const hrColor = organization?.anniversaryWordHexCode ?? undefined;
  const bannerClassName = organization?.bannerGrayscale && organization.bannerGrayscale >= 128 ? "light" : "dark";

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <Meta />
        <Links />
      </head>
      <body>
        <header style={{ backgroundColor: bannerColor }}>
          &nbsp;
          <img
            className="customer-logo"
            src={`/logos/${logoFileName}`}
            alt="Organization logo"
            style={{ maxWidth: "90%", maxHeight: "50px" }} />

        </header>
        {bannerColor === "#ffffff" && <hr style={{ borderColor: hrColor }} />}
        <MessageBanner />
        <Outlet />
        {bannerColor === "#ffffff" && <hr style={{ borderColor: hrColor }} />}
        <footer style={{ backgroundColor: bannerColor }} className={bannerClassName}>
          <span className="footer-item">
            Copyright &copy; 2023–{new Date().getUTCFullYear()}{" "}
            <a href="https://www.workiversary.com" target="_blank" rel="noreferrer">
              The Workiversary Group
            </a>
          </span>
          <span className="footer-item">
            <a href="https://www.workiversary.com/privacy" target="_blank" rel="noreferrer">
              Our privacy policy
            </a>
          </span>
        </footer>

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export function useOrganization(): Organization | null {
  const contributeRouteId = "routes/contribute.$uuid";
  const contributor = useRouteData<Contributor>(contributeRouteId);
  const thankYouRouteId = "routes/thank-you.$uuid";
  const thankYouLoaderData = useRouteData<ThankYouLoaderData>(thankYouRouteId);
  if (contributor) {
    return contributor.anniversary.organization;
  } else if (thankYouLoaderData) {
    return thankYouLoaderData.contributor.anniversary.organization;
  } else {
    return null;
  }
}

export function ErrorBoundary() {
  //
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h1>Oops, something went wrong</h1>
        <p>
          Please try again or email <a href="mailto:the.team@workiversary.com">the.team@workiversary.com</a> for
          assistance.
        </p>
        <hr />
        <p>
          {error.status} {error.statusText}
        </p>
        <p>{error.data}</p>
      </>);

  }
  const message = String(
    error && (error as {message: unknown;}).message ? (error as {message: unknown;}).message : "Unknown error"
  );
  return (
    <div style={{ margin: "2rem" }}>
      <h1>Oops, something went wrong 🫤</h1>
      <p>
        Please try again or email <a href="mailto:the.team@workiversary.com">the.team@workiversary.com</a> for
        assistance.
      </p>
      <p>Apologies for the inconvenience.</p>
      <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
      <pre style={{ color: "gray" }}>More details: {message}</pre>
    </div>);

}